"use client";

import Button from "hardcover-ui/components/Button";
import { useEffect } from "react";
import Text from "hardcover-ui/components/Text";
import NotFoundView from "./NotFoundView";
import Bugsnag from "lib/bugsnag/client";
import Container from "hardcover-ui/components/Container";
import Heading from "hardcover-ui/components/Heading";
import { enlargeUrl } from "lib/imageTools";
import { useRouter } from "next/navigation";

export default function ServerErrorView({
  clearError,
  error,
}: {
  error: Error;
  clearError: () => void;
}) {
  const router = useRouter();

  useEffect(() => {
    if (error?.message !== "NEXT_NOT_FOUND") {
      Bugsnag.notify(error);
    }
  }, []);

  const retry = () => {
    clearError();
    window.location.reload();
  };

  const goBack = () => {
    router.back();
    router.refresh();
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  if (error?.message === "NEXT_NOT_FOUND") {
    return <NotFoundView />;
  }

  return (
    <Container variant="layout" size="sm" className="text-center">
      <img
        src={enlargeUrl({
          path: "/static/errors/400-error.png",
          width: 480,
          height: 404,
        })}
        alt="Jules looking confused at the server"
        width={480}
        height={404}
        className="mx-auto"
      />

      <Heading level={1}>Oops! Our servers are acting up!</Heading>

      <div>
        <Text
          variant="secondary"
          as="p"
          className="max-w-lg text-center mx-auto"
        >
          We're very embarrassed by this, but something went wrong. Adam &amp;
          Ste have been notified and they're on the case!
        </Text>
        <p className="mt-4">
          <Button variant="primary" size="lg" onClick={retry}>
            Try Again?
          </Button>
        </p>

        <p className="mt-4">
          <Button variant="secondary" size="md" onClick={goBack}>
            <>Go back</>
          </Button>
        </p>
      </div>
    </Container>
  );
}
